/* General Tab Styles */
.tabs-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    
    padding: 0;
    width: 100%;
    box-sizing: border-box; /* Include padding and borders in the width */
}
/* General Tab Styles */
.tabs {
    display: flex;
    justify-content: center;
    width: 100%;
    text-align: center;
    margin-bottom: 2px;
    gap: 0px;
    box-sizing: border-box;
}

/* Active Tab Style - Smaller height and font */
.active-tab {
    background: linear-gradient(145deg, rgba(45, 45, 68, 0.9), rgba(55, 55, 72, 0.9));
    color: white;
    padding: 6px 15px; /* Reduced padding for smaller height */
    font-size: 16px; /* Smaller font size */
    cursor: pointer;
    display: flex;
    align-items: center;

    position: relative;
    z-index: 1;
    transition: background 0.4s ease;
}

/* Add sliding bottom border effect using ::after */
.active-tab::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: #8800ffd0;
    transition: transform 0.4s ease; /* Animation for sliding effect */
    transform: scaleX(1);
    transform-origin: left; /* Slide from left */
}

/* Inactive Tab Style - Smaller height and font */
.not-active-button {
    background: linear-gradient(145deg, rgba(45, 45, 68, 0.9), rgba(55, 55, 72, 0.9));
    color: white;
    padding: 6px 15px; /* Reduced padding for smaller height */
    font-size: 16px; /* Smaller font size */
    cursor: pointer;
    display: flex;
    align-items: center;

    position: relative;
    transition: background 0.4s ease;
}

/* No bottom border for inactive tabs */
.not-active-button::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: #8800ff;
    transform: scaleX(0); /* Border is hidden */
    transform-origin: right; /* Slide towards the right */
    transition: transform 0.4s ease; /* Animation for sliding effect */
}

/* Hover effect for tabs */
.tabs button:hover::after {
    transform: scaleX(1); /* Show border on hover */
    transform-origin: left;
}

.not-active-button:hover {
    
    background-color: #444;

}
.active-tab:hover {
    background-color: #444;
}

.tab-bump-bots{
    justify-content: center; /* Center text horizontally */
    align-items: center; /* Center text vertically */
    text-align: center; /* Center text inside the button */    
    width: 50%;
    font-size: 16px;
    height: 40px;
    border-top-left-radius: 8px;
    border-top-right-radius: 0px;
}
.tab-launches{
    justify-content: center; /* Center text horizontally */
    align-items: center; /* Center text vertically */
    text-align: center; /* Center text inside the button */    
    width: 50%;
    height: 40px;
    font-size: 16px;
    border-top-right-radius: 8px;
    border-top-left-radius: 0px;
}
/* Hover effect for inactive tabs */


/* Content Container */
.tab-content-container-pump-fun {
    padding: 40px;
    background: rgba(45, 45, 68, 0.9);
    border-radius: 0px 0px 10px 10px; /* Only bottom corners rounded */

    border-bottom: 2px solid #8800ff; /* Matching border with the tabs */

    width: 100%;
    margin-top: -2px; /* Ensures seamless connection with tabs */
    box-sizing: border-box; /* Include padding and borders in the width */
}

.launches-content h3 {
    font-size: 24px;
    margin-bottom: 20px;
}

.launches-content p {
    font-size: 16px;
}

/* Bot Section Styling */
.tab-content-pump-fun {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0px;
    background: linear-gradient(145deg, rgba(45, 45, 68, 0.9), rgba(80, 80, 110, 0.9));
    border-radius: 10px;
    box-shadow: 0 2px 10px #8800ff;
    color: #fff;
}

/* BumpBots Menu */
.bump-bot-icon-img {
    margin-top: -4px;
    margin-right: 10px;
    width: 40px;
    height: 40px;
}


@media (max-width: 768px) {
    .tab-bump-bots, .tab-launches {
        width: 50%; /* Keep the width at 50% to ensure both tabs fit side by side */
        height: 40px;
        font-size: 14px; /* Slightly smaller font size */
        padding: 5px; /* Reduce padding */
    }

    .tabs {
        flex-direction: row; /* Ensure tabs remain in a row layout */
        gap: 0; /* Remove gap for a compact design */
    }
    .tab-content-pump-fun {
      flex-direction: column;
      padding: 10px;
    }
  }
  