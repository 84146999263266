/* Settings Container */
.settings-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    padding: 20px;
    color: #e0e0e0; /* Light text for dark theme */
    background: linear-gradient(145deg, rgba(35, 35, 54, 0.9), rgba(72, 72, 102, 0.9)); /* Gradient with transparency */
    box-shadow: #8800ff;
}
  
/* RPC URL Input Container */
.rpc-url-container {
    display: flex;
    
    align-items: center;
    justify-content: center;
    gap: 10px; /* Space between input and button */
    margin-bottom: 20px;
}
  
/* RPC URL Input Field */
.rpc-url-container input {
    padding: 10px;
    font-size: 16px;
    background-color: #464d5c; /* Dark input background to match theme */
    color: white; /* Light text */
    border: none; /* No border for clean look */
    border-radius: 10px; /* Rounded corners */
    width: 300px; /* Set width */
    outline: none; /* Remove input outline */
    transition: background-color 0.3s ease;
}
  
/* Input hover/focus effect */
.rpc-url-container input:focus,
.rpc-url-container input:hover {
    background-color: #555; /* Slightly lighter on hover/focus */
}
  
/* Confirm Button */
.confirm-btn {
    background-color: #8800ff; /* Muted darker purple button */
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 10px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 0px;
}
  
/* Confirm Button Hover Effect */
.confirm-btn:hover {
    background-color: #a64dff; /* Darker purple on hover */
}

@media (max-width: 768px) {
    .settings-container {
      padding: 10px;
    }
    .rpc-url-container {
      flex-direction: column;
      gap: 5px;
    }
    .rpc-url-container input {
      width: 100%;
    }
  }
  