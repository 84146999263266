
/* BumpBots Menu */
.bump-bots-container {
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding: 20px;
    border-radius: 15px;
    color: #fff;
    margin-left: -20px;
}
.bot-list{
    display: flex;
    justify-content: center;
    flex-wrap: wrap; /* Allow items to wrap to the next row */
    gap: 20px; /* Optional: space between rows */
}
.bot-item {
    background-color: #3b3b52;
    border-radius: 12px;
    padding: 20px;
    margin-bottom: 20px;
    width: 28%; /* 3 items per row, subtract the gap */
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    overflow: hidden;
}

.bot-item:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.3);
}

.down-arrow-bump-bot{
    height: 15px;
    margin-top: 5px;
}
.bot-name {
    background: none;
    border: none;
    color: #fff;
    font-size: 16px;
    width: 120px;
}
.bot-more-info.hidden {
    display: none; /* Completely hide the content */
  }
  
  /* Show the "More Info" section only when expanded */
  .bot-more-info.visible {
    display: block; /* Show the content only when expanded */
    background-color: #444466; /* Dark background */
    border-radius: 8px;
    padding: 10px;
    margin-top: 10px;
    transition: max-height 0.3s ease;
  }

.bot-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.bot-details p {
    margin: 5px 0;
}

button {
    background-color: #8800ff;
    color: #fff;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
}

button:hover {
    background-color: #8800ff;
}

/* Keep the right side open for future content or other widgets */
.right-content {
    width: 70%; /* Occupies the remaining 70% */
    padding: 20px;
    background-color: #2d2d44;
    border-radius: 10px;
    color: #fff;
    height: auto;
    margin-left: 5%;
}

.add-bot-button {
    background-color: #8800ff;
    color: #fff;
    border: none;
    height: 40px;
    border-radius: 10px;
    font-size: 18px;
    cursor: pointer;

}

.add-bot-button:hover {
    background-color: #a64dff;
}

/* Style for the form container */
/* Overlay for blurring the background when form is shown */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5); /* Darken background */
    z-index: 999; /* Ensures overlay is above the rest of the content */
    
}

/* Add blur effect to the background when the form is active */
.blurred {
    filter: blur(5px); /* Blurs the background content */
}
@keyframes slideUpCreateBot {
    0% {
        transform: translate(-50%, 100%); /* Start off-screen (below) */
        opacity: 0;
    }
    100% {
        transform: translate(-50%, -50%); /* End at center */
        opacity: 1;
    }
}
/* Center the form on the screen */
.bot-form-container {
    background-color: #2d2d44;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);
    width: 300px;
    z-index: 1000;
    color: white;    
    animation: slideUpCreateBot 0.5s forwards ease-in-out; /* Animate */
}


.form-row {
    display: flex;
    justify-content: space-between;
    display: inline-flex;
    gap: 10px;
}



.bot-form input {
    width: 100%;
}
/* Form styling */
.bot-form h3 {
    margin-bottom: 15px;
    text-align: center;
}

.bot-form label {
    display: block;
    margin-bottom: 10px;
    font-size: 14px;
}

.bot-form input,
.bot-form select {
    width: 100%;
    color: white;
    background-color: #464d5c;
    padding: 8px;
    margin-top: 5px;
    margin-bottom: 15px;
    border-radius: 5px;
    border: none;
    box-sizing: border-box;
}

.bot-form button[type="submit"] {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    margin-top: 10px;
    background-color: #8800ff;
    color: white;
}

.bot-form button[type="submit"]:hover {
    background-color: #a64dff;
}

/* Close (X) button */
.close-button {
    background: none;
    border: none;
    color: #fff;
    font-size: 20px;
    position: absolute;
    top: 10px;
    right: 15px;
    cursor: pointer;
}

.close-button:hover {
    color: #d9534f;
}

.bump-bot-calculated-cost{
    margin-top: 5px;
    text-align: justify;
    margin-bottom: 5px;
}

/* Modal Styling */
.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #2d2d44;
    color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    z-index: 1000;
}
.modal-error{
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #2d2d44;
        color: #fff;
        padding: 20px;
        border-radius: 10px;
        max-width: 350px;
        border: 2px solid #ff5c5c;        
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
        z-index: 1000;
    }

    .modal-success{
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #2d2d44;
        color: #fff;
        padding: 20px;
        border-radius: 10px;
        max-width: 350px;
        border: 2px solid #2bb078; /* Purple for success */
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
        z-index: 1000;
    }


.modal-content {
    text-align: center;
}

.modal-buttons {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

/* Loading Box Container */
.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}

/* Box containing the spinner and the message */
.loading-box {
    display: flex;
    align-items: center;
    background-color: #2d2d44;
    color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
}

/* Spinner inside the box */
.spinner {
    border: 4px solid #f3f3f3; /* Light grey */
    border-top: 4px solid #8800ff; /* Purple */
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 1s linear infinite;
    margin-left: 0px;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}


/* Bot List */




.bot-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.bot-title {
    font-size: 18px;
    font-weight: 600;
    color: #ffffff;
    margin : 0;
}

.bot-taskId {
    font-size: 11px;
    color: #bbbbbb;
    margin: 0;
    cursor: pointer;
    user-select: none;
}

.bot-taskId:hover {
    text-decoration: underline;
}
.status-label {
    font-size: 14px;
    color: #ffffff;
    margin-bottom: 10px;
}

/* Time left and progress bar */
.time-left-label {
    font-size: 14px;
    color: #ffffff;
    margin-bottom: 10px;
}

.progress-bar {
    width: 100%;
    height: 11px;
    margin-top: -10px;
    background-color: #555;
    border-radius: 5px;
    overflow: hidden;
    margin-top: 5px;
}

.progress {
    height: 100%;
    background-color: hsl(124, 45%, 43%);
    transition: width 0.3s ease;
}


.sol-balance-menu {
    background-color: #444466; /* Dark background to match the theme */
    border-radius: 8px;
    padding: 10px;
    margin-top: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    transition: slideUp 0.3s ease;
    animation: expandMenu 0.5s forwards ease-in-out; /* Play the animation */  

}
  
  .sol-balance {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  /* Animation keyframes */
@keyframes expandMenu {
    0% {
        width: 0;
        opacity: 0;
    }
    100% {
        width: 100px; /* Final width when expanded */
        opacity: 1;
    }
}
  .sol-balance p {
    font-size: 14px;
    color: #ffffff;
    margin: 0;
  }
  
  .withdraw-icon {
    font-size: 18px;
    color: #fff;
    cursor: pointer;
  }
  
  .withdraw-icon:hover {
    color: #a64dff; /* Purple hover effect */
  }


.refresh-btn:hover {
    background-color: #a64dff;
}

.token-name {
    font-size: 14px;
    color: #ffffff;
    margin-bottom: 10px;
}

.show-more-btn {
    background-color: #8800ff;
    color: #ffffff;
    border: none;
    padding: 10px;
    border-radius: 8px;
    cursor: pointer;
    width: 100%;
    text-align: center;
    transition: background-color 0.3s ease;
}

.show-more-btn:hover {
    background-color: #a64dff;
}

.bot-more-info {
    margin-top: 10px;
    padding: 10px;
    background-color: #444466;
    border-radius: 8px;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.3);
    color: #ffffff;
    font-size: 14px;
}

.yes-no-button{
    margin-right: 5px;
    margin-left: 5px;
}

.bump-bots-title{
    font-size: 20px;
    margin-top: 00px;
    text-align: center;
}

.end-delete-bump {
    display: flex;
    justify-content: start; /* Center the buttons horizontally */
    gap: 5px; /* Reduce the space between buttons */
    margin-top: 10px; /* Optional: Adjust the spacing between buttons and other elements */
}

.refresh-btn, .end-btn {
    background-color: #8800ff;
    color: #ffffff;
    border: none;
    padding: 10px;
    border-radius: 8px;
    cursor: pointer;
    width: 48%;
    transition: background-color 0.3s ease;
}

.refresh-btn:hover, .end-btn:hover, .yes-no-button:hover {
    background-color: #a64dff;
}
.buttons-wrapper {
    display: flex;
    justify-content: space-between; /* Distribute left and right buttons */
    align-items: center; /* Align buttons vertically */
    margin-top: 10px;
}

.left-buttons {
    display: flex;
    gap: 10px
}

.right-buttons {
    display: flex;
    gap: 10px; /* Space between Stop and Delete buttons */
}

.delete-btn {
    background-color: #ff3333;
}

.delete-btn:hover {
    background-color: #ff5c5c;
}

.transfer-btn {
    background-color: #8800ff;
}

.transfer-btn:hover {
    background-color: #a64dff;
}

.stop-btn {
    background-color: #e6b800;
}
button:disabled {
    background-color: #555; /* A grey color to indicate it's disabled */
    cursor: not-allowed; /* Change cursor to indicate it's not clickable */
    opacity: 0.6; /* Make it slightly transparent */
}
button:disabled:hover {
    background-color: #555; /* A grey color to indicate it's disabled */
    cursor: not-allowed; /* Change cursor to indicate it's not clickable */
    opacity: 0.6; /* Make it slightly transparent */
}
.stop-btn:hover {
    background-color: #ffcc00;
}
.info-btn {
    background-color: #8800ff; 
}

.info-btn:hover {
    background-color: #a64dff;
}
/* Modal Overlay (Background) */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex; /* Flexbox to center */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    z-index: 1000;
}

/* Modal content */
/* Enlarge Modal Size */
.info-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* Center horizontally and vertically */
    background-color: #2d2d44;
    color: white;
    width: 80%;
    max-width: 400px;
    padding: 30px;
    border-radius: 12px;
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.4);
    z-index: 1001;
    text-align: center;
    animation: slideUp 0.4s;
}

/* Info Field Styling */
.info-field {
    background-color: #444466; /* Dark background to differentiate */
    padding: 15px; /* Padding inside the field */
    margin-bottom: 15px; /* Spacing between fields */
    border-radius: 8px; /* Rounded corners */
    box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.3); /* Inner shadow */
    font-size: 14px; /* Text styling */
}
.info-field-keys {
    background-color: #444466; /* Dark background to differentiate */
    padding: 15px; /* Padding inside the field */
    margin-bottom: 15px; /* Spacing between fields */
    border-radius: 8px; /* Rounded corners */
    box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.3); /* Inner shadow */
    font-size: 14px; /* Text styling */
}

/* Button to show private keys */
.show-keys-btn {
    background-color: #8800ff;
    color: #fff;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
    width: 50%; /* Full-width button */
    text-align: center;
}

.show-keys-btn:hover {
    background-color: #a64dff;
}


/* Slide up animation */

@keyframes slideUp {
    from {
        top: 100%;
    }
    to {
        top: 50%; /* Center it vertically */
    }
}

/* Close button inside the modal */
.close-button {
    background: none;
    border: none;
    color: #fff;
    font-size: 20px;
    position: absolute;
    top: 10px;
    right: 15px;
    cursor: pointer;
}

.close-button:hover {
    color: #ff5c5c;
}


@media (max-width: 768px) {
    .bot-item {
      width: 100%; /* Make bots full width */
    }
    .right-content {
      width: 100%;
      margin-left: 0;
    }
    
  }
  