.rank-bots-container {
    position: relative;
    padding: 20px;
}

.add-button {
    background-color: #8800ff;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 20px;
    cursor: pointer;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    transition: background-color 0.3s ease;
}

.add-button:hover {
    background-color: #5500cc;
}

.rank-bots-form {
    margin-top: 20px;
    background-color: rgba(45, 45, 68, 0.9);
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px #8800ff;
}

.form-group {
    margin-bottom: 15px;
}

.form-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
    color: white;
}

.form-group input[type="text"] {
    width: 100%;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
}

.form-group input[type="range"] {
    width: 100%;
}

.create-button {
    background-color: #8800ff;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}

.create-button:hover {
    background-color: #5500cc;
}

@media (max-width: 768px) {
    .rank-bots-container {
      padding: 10px;
    }
    .form-group input[type="text"] {
      width: 100%; /* Adjust input to fill the screen */
    }
    .add-button, .create-button {
      font-size: 16px;
      padding: 8px 15px;
    }
  }
  