/* General Dark Theme and Layout */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  background-color: #1b1b2f; /* Darker background with a purple hue */
  color: #e0e0e0; /* Light text for contrast */
}

.App-header {
  background-color: #2d2d44; /* Dark gray with a purple tint */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #e0e0e0;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/* Purple Accent for Links */
.App-link {
  color: #8800ff; /* Muted darker purple */
}

/* Animation */
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Blur Background when API Key is requested */
.blur-background {
  filter: blur(5px);
  pointer-events: none; /* Prevent interaction with blurred elements */
}

/* API Key Modal */
.api-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8); /* Semi-transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.api-modal-content {
  background-color: #2a2a3b; /* Darker modal background */
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  color: #fff;
  border-bottom: 2px solid #8800ff;
}

.api-modal input {
  background-color: #444; /* Darker input background */
  border: none;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  width: 300px;
  margin-right: 10px;
}

.api-modal button {
  background-color: #8800ff; /* Muted darker purple button */
  border: none;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.api-modal button:hover {
  background-color: #a64dff; /* Darker purple on hover */
}

/* Tab Navigation */
.tab-navigation {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  position: relative;
}

/* Hamburger Menu Styles */
.hamburger-menu {
  display: none; /* Hidden by default, shown on mobile */
  position: relative;
}

.hamburger-icon {
  background: none;
  border: none;
  color: #e0e0e0;
  cursor: pointer;
}

.dropdown-menu {
  position: absolute;
  top: 60px; /* Adjust based on your layout */
  right: 0;
  background-color: #2d2d44;
  border: 1px solid #8800ff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1001;
  width: 150px;
  display: flex;
  flex-direction: column;
}

.dropdown-item {
  background: none;
  border: none;
  color: #e0e0e0;
  padding: 10px 15px;
  text-align: left;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.dropdown-item:hover {
  background-color: #393965;
}

.dropdown-item.active {
  background-color: #8800ff;
  color: white;
}

/* Tab Buttons for Desktop */
.tab-buttons {
  display: flex;
}

/* Tab Button Styles */
.tab-button {
  background: linear-gradient(145deg, rgba(35, 35, 54, 0.9), rgba(72, 72, 102, 0.9)); /* Gradient with transparency */
  color: #e0e0e0;
  border: none;
  padding: 10px 30px;
  margin: 0 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 50px;
}

.tab-button:hover {
  background-color: #393965; /* Slightly lighter gray-purple on hover */
  transform: translateY(-2px);
}

.tab-button.active {
  border-bottom: 2px solid #8800FF;
  color: white;
}

.no-key-text {
  color: white;
  text-decoration: none;
  margin-bottom: 0px;
  font-size: 13px;
}

a {
  text-decoration: none;
}

/* Tab Content Container */
.tab-content-container {
  margin-top: 30px;
  padding: 40px;
  border-radius: 20px;
  color: #fff;
  transition: opacity 0.5s ease, transform 0.5s ease;
  width: 50%;
  height: 50%;
  min-width: 1300px;
  margin: 50px auto;
}

.tab-content {
  font-size: 18px;
  animation: fadeInUp 0.5s ease-in-out;
  color: #e0e0e0; /* Light text color */
}

/* Icons for tabs */
.pumpfuntab-icon,
.raydiumtab-icon,
.settingstab-icon {
  object-fit: contain;
}

.pumpfuntab-icon {
  width: 27px;
  height: 50px;
}

.raydiumtab-icon {
  width: 45px;
  height: 50px;
}

.settingstab-icon {
  width: 30px;
  height: 25px;
}

/* Wallet Section */
/* Wallet Section */
.wallet-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.wallet-modal-content {
  background-color: #2a2a3b; /* Dark background */
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 5px 15px #8800ff;
  position: relative;
  max-width: 400px;
  width: 90%;
  text-align: center;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 15px;
  background: none;
  border: none;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
}

.wallet-modal-content {
  background-color: #2a2a3b; /* Dark background */
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  position: relative;
  max-width: 450px;
  width: 90%;
  text-align: center;
}

.deposit-address {
  margin-top: 20px;
  font-size: 16px;
  color: #e0e0e0;
}

.qr-code {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wallet-action-button {
  background-color: #8800ff;
  border: none;
  color: white;
  padding: 10px 20px;
  margin-top: 50px;
  border-radius: 5px;
  cursor: pointer;
}

.wallet-action-button:hover {
  background-color: #a64dff;
}

/* Withdrawal Prompt Styles */
.withdraw-prompt-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.withdraw-prompt-content {
  background-color: #2a2a3b;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  text-align: center;
  max-width: 400px;
  width: 90%;
  position: relative;
}

.withdraw-input {
  width: 80%;
  padding: 10px;
  margin-top: 0px;
  margin-bottom: 20px;
  border-radius: 5px;
  border: none;
  background-color: #444;
  color: #fff;
}

.confirm-button {
  background-color: #8800ff;
  border: none;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
}

.confirm-button:hover {
  background-color: #a64dff;
}

.centered {
  display: block;
  margin: 0 auto;
}

/* Loading Spinner Styles */
.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loading-spinner {
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid #8800ff; /* Purple */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin-bottom: 10px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.wallet-button {
  display: flex;
  align-items: center;
  background-color: #1b1b2f;
  border: none;
  color: white;
  padding: 10px 20px;
  border-radius: 25px;
  cursor: pointer;
}

.wallet-button:hover {
  background-color: #393965;
}

.wallet-icon {
  width: 40px;
  height: 35px;
  margin-right: 10px;
}

.wallet-balance {
  margin-top: 10px;
}

/* Fade-in and slide-up animation */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.solana-balance-icon{
  width: 10px;
}

/* Message Modal Styles */
.message-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.max-button {
  position: absolute;
  right: 30px;
  padding: 10px 12px;
  top: 0px;
  background-color: #8800ff;
  border: none;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}

.max-button:hover {
  background-color: #a64dff;
}

.message-content {
  background-color: #2a2a3b;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  text-align: center;
  max-width: 400px;
  width: 90%;
}

.message-content.success {
  border: 2px solid #2bb078; /* Purple for success */
}

.message-content.error {
  border: 2px solid #ff5c5c; /* Red for error */
}

/* Styles for the input field and MAX button */
.withdraw-amount-input {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
}

.withdraw-input {
  width: 80%;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
  border: none;
  background-color: #444;
  color: #fff;
}

.ok-button {
  background-color: #8800ff;
  border: none;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
}

.ok-button:hover {
  background-color: #a64dff;
}

.turbo-fun-icon-top-left{
  position: absolute;
  height: 85px;
  top: 8px;
  left: 10px;
}

@keyframes slideIn {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slideOut {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}

.notification {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #14734c;
  color: white;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  animation: slideIn 0.5s forwards, slideOut 0.5s 4.5s forwards;
}

/* Dropdown Menu Styles */
.dropdown-menu {
  display: flex;
  flex-direction: column;
}

.dropdown-item {
  background-color: #2d2d44;
  border: none;
  color: #e0e0e0;
  padding: 10px 15px;
  text-align: left;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.dropdown-item:hover {
  background-color: #393965;
}

.dropdown-item.active {
  background-color: #8800ff;
  color: white;
}

/* Media Queries for Mobile Responsiveness */
@media (max-width: 768px) {
  /* Hide Tab Buttons and Show Hamburger Menu */
  .tab-buttons {
    display: none;
  }

  .hamburger-menu {
    display: block;
  }

  /* Adjust Tab Content Container */
  .tab-content-container {
    width: 90%; /* Full width on mobile */
    min-width: auto; /* Remove the fixed width */
    margin: 20px auto;
    padding: 20px;
  }

  /* Adjust API Modal Content */
  .api-modal-content {
    width: 90%;
  }

  /* Center the Hamburger Menu */
  .tab-navigation {
    justify-content: center;
  }

  /* Adjust Dropdown Menu Position */
  .dropdown-menu {
    top: 60px; /* Adjust based on your layout */
    right: auto;
    left: 40%;
    transform: translateX(-50%);
  }

  /* Adjust Hamburger Icon Size */
  .hamburger-icon {
    font-size: 24px;
    margin-right: 180px;
  }

  /* Adjust Wallet Section Position */
  .wallet-section {
    position: fixed;
    top: 10px;
    right: 10px;
  }

  /* Adjust TurboFun Icon */
  .turbo-fun-icon-top-left {
    position: fixed;
    top: 25px;
    left: 10px;
    height: 60px;
  }
  .wallet-button{
    margin-top: 10%;
  }

}
